/**
 File to declare constants related to Optimizely experiments
 */

const PROJECT_ID = '11355970984' // production
const TEST_PROJECT_ID = '17294405044' // stage

/**
 * We have set up proxying for optimizely through our CDN
 * @readonly
 */
const OPTIMIZELY_URL = 'https://optimizely-cdn.ethoslife.com/js'

export const OPTIMIZELY_SCRIPT_SRC = `${OPTIMIZELY_URL}/${PROJECT_ID}.js`
export const OPTIMIZELY_SCRIPT_SRC_DEV = `${OPTIMIZELY_URL}/${TEST_PROJECT_ID}.js`

// Used to trigger Daily Premium pricing on BoF Package Select
// Also used to trigger Quick Scan treatment on PreInterview /q/recommendation
export const DAILY_DOLLARS_COOKIE = {
  NAME: 'ETHOS_DAILY_DOLLARS_TOF',
  EW_VALUE: 'DAILY_EW',
}

// Matching LP copy to ad campaign copy
// https://ethoslife.atlassian.net/browse/GC-2183
// https://app.optimizely.com/v2/projects/15340850549/experiments/24139470024/variations
export const LP_CAMPAIGN_1 = {
  EXPERIMENT_KEY: 'lpCampaign8888464272',
  VARIATIONS: {
    CONTROL: 'original',
    TREATMENT: 'TREATMENT',
  },
  HEADING: 'Instant term life insurance',
}

/**
 * flag for the Final Expense fake preinterview flow
 */

export const FINAL_EXPENSE_FAKE_PREINTERVIEW = {
  EXPERIMENT_KEY: 'gc-4104-fake-fe-flow',
  VARIATIONS: {
    CONTROL: 'off',
    DELAYED_PII: 'delayed_pii',
    GROWTH_WINS: 'growth_wins',
  },
}

/**
 * Experiment to simulate a landing page that looks like the start of the main app
 */

export const ASTA = {
  VARIATIONS: {
    TREATMENT: 'TREATMENT',
  },
  COOKIE_NAME: 'asta', // same as experiment key but want to be explicit
}

export const FINAL_EXPENSE_WIDGET = {
  EXPERIMENT_KEY: 'xp-fe-widget-gc-4077',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT: 'treatment',
  },
  PAGE_VALUES: {
    control: 'finalExpense-control',
    treatment: 'finalExpense-treatment',
  },
}

export const UPDATED_CP_FE_LP = {
  EXPERIMENT_KEY: 'updated_cp_experience',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment1', // Without Qoute
    TREATMENT_2: 'treatment2', // With sales
  },
  PAGE_VALUES: {
    control: 'finalExpense-control',
    treatment: 'finalExpense-treatment',
  },
}

export const UPDATED_CP_FE_LP_NON_WORKING_HOURS = {
  EXPERIMENT_KEY: 'updated_cp_experience_non_working_hours',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment1', // Without Qoute
  },
  PAGE_VALUES: {
    control: 'finalExpense-control',
    treatment: 'finalExpense-treatment',
  },
}

export const FE_LP_NEW_HEADER = {
  EXPERIMENT_KEY: 'fe_lp_vs_new_header_cc-4007',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
  },
}

export const FE_INCOMING_CALLS_LP = {
  EXPERIMENT_KEY: 'fe_incoming_calls_lp',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
    TREATMENT_3: 'treatment_3',
  },
}

export const FE_ESTIMATE_WIDGET_WITH_PHONE = {
  EXPERIMENT_KEY: 'fe_est_widget_with_phone',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills-painted-door-v2-gc-4357/rules/development
export const WILLS_PAINTED_DOOR_V2_FLOW = {
  EXPERIMENT_KEY: 'wills-painted-door-v2-gc-4357',
  VARIATIONS: {
    CONTROL: 'off',
    SHORT_FLOW: 'short_flow',
    LONG_FLOW_TREATMENT_1: 'long_flow_treatment_1',
    LONG_FLOW_TREATMENT_2: 'long_flow_treatment_2',
    V_2_2_1_TREATMENT_3: 'v2_2_1_treatment_3',
    V_2_2_1_TREATMENT_1: 'v2_2_1_treatment_1',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills-30-day-trial-gc-4670/rules/development
export const WILLS_30_DAY_TRIAL_FLOW = {
  EXPERIMENT_KEY: 'wills-30-day-trial-gc-4670',
  VARIATIONS: {
    CONTROL: 'off',
    V_2_2_1_TREATMENT_1: 'v2_2_1_treatment_1',
    TRIAL_TREATMENT: 'trial_treatment',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills-funnel-flow-gc-4121/variations
export const WILLS_FUNNEL_FLOW = {
  EXPERIMENT_KEY: 'wills-funnel-flow-gc-4121',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
    TREATMENT_3: 'treatment_3',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills_lp/rules/development
export const WILLS_LP = {
  EXPERIMENT_KEY: 'wills_lp',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
    TREATMENT_3: 'treatment_3',
  },
  PAGE_VALUES: {
    control: 'wills_lp_treatment1',
    treatment: 'wills_lp_treatment3',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills_lp_v2_2/rules/development
export const WILLS_LP_V2_2 = {
  EXPERIMENT_KEY: 'wills_lp_v2_2',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
  },
  PAGE_VALUES: {
    control: 'wills_lp_v2_2_control',
    treatment: 'wills_lp_v2_2_treatment',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/question_template_preinterview/rules/development
export const QUESTION_TEMPLATE = {
  EXPERIMENT_KEY: 'question_template_preinterview',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT: 'treatment',
  },
}
/*
 * Wills And Trust pricing experiment Flag
 * https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills_pricing_experiment__gc-4671/rules/development/edit/wills_pricing_experiment__gc-4671
 */

export enum WillsAndTrustPricingVariants {
  OFF = 'off',
  TREATMENT_1 = 'treatment_1',
  TREATMENT_2 = 'treatment_2',
}

export const WILLS_AND_TRUST_PRICING_FLAG = {
  EXPERIMENT_KEY: 'wills_pricing_experiment__gc-4671',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
  },
}

export const SINGLE_CTA_EXPERIMENT = {
  EXPERIMENT_KEY: 'single_cta_experiment',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
  },
  COOKIE: {
    NAME: 'single_cta',
    VALUE: 'true',
  },
}

export const DIRECT_TO_APP_UX = {
  EXPERIMENT_KEY: 'direct-to-app-ux-gc-4732',
  VARIATIONS: {
    CONTROL: 'off',
    VARIANT_1: 'variant_1',
    VARIANT_2: 'variant_2',
    VARIANT_3: 'variant_3',
  },
  COOKIE: {
    NAME: 'direct_to_app',
    VALUE: 'true',
  },
}

export const PRIVACY_POLICY_BANNER = {
  EXPERIMENT_KEY: 'privacy_consent_gc-4787',
  VARIATIONS: {
    CONTROL: 'off',
    VARIANT_1: 'variant_1',
    VARIANT_2: 'variant_2',
    VARIANT_3: 'variant_3',
    VARIANT_4: 'variant_4',
    VARIANT_5: 'variant_5',
  },
}
