import { SINGLE_CTA_EXPERIMENT } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useSingleCTAExperiment = () => {
  const { TREATMENT_1, TREATMENT_2, CONTROL } = SINGLE_CTA_EXPERIMENT.VARIATIONS
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: SINGLE_CTA_EXPERIMENT.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  const TEXT_CONSTANT = {
    ctaLabel: 'Start applying',
  }

  return {
    isLoading,
    isTreatment: isEnabled && [TREATMENT_1, TREATMENT_2].includes(variation),
    isTreatment1: isEnabled && variation === TREATMENT_1,
    isTreatment2: isEnabled && variation === TREATMENT_2,
    textConstant: TEXT_CONSTANT,
  }
}
